import {api} from './api';

const actualizeFavoriteCount = (favoriteCount) => {
    api.get('favorite/count/')
        .json()
        .then((response) => {
            favoriteCount.innerHTML = response.total;
            favoriteCount.style.display = 'block';
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

const initFavoriteCountHandler = () => {
    const favoriteCount = document.querySelector('[data-entity="favorite-icon-count"]');

    if (favoriteCount) {
        actualizeFavoriteCount(favoriteCount);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    initFavoriteCountHandler();
});

export {initFavoriteCountHandler};
