const switchFieldVisibility = (idList, isHide) => {
    if (idList.length <= 0) {
        return;
    }

    idList.forEach((item) => {
        const fieldWrap = document.querySelector(`#${item}`);

        if (fieldWrap) {
            const formElements = fieldWrap.querySelectorAll('[name]');

            formElements.forEach((item) => {
                const errorClassName = `${item.classList[0]}--error`;

                item.classList.remove(errorClassName);
                item.disabled = isHide;
                item.required = !isHide;
                item.tabIndex = isHide ? '-1' : '0';
            });

            fieldWrap.classList.toggle('visually-hidden', isHide);
        }
    });
};

const switchFieldInit = () => {
    const triggerElements = document.querySelectorAll('.switch-field-trigger');

    if (triggerElements.length > 0) {
        triggerElements.forEach((item) => {
            item.addEventListener('change', (evt) => {
                const displayedFields = evt.target.dataset.displayedFields
                    ? evt.target.dataset.displayedFields.split(' ')
                    : [];
                const hiddenFields = evt.target.dataset.hiddenFields ? evt.target.dataset.hiddenFields.split(' ') : [];

                if (displayedFields.length > 0) {
                    switchFieldVisibility(displayedFields, false);
                }

                if (hiddenFields.length > 0) {
                    switchFieldVisibility(hiddenFields, true);
                }
            });
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    switchFieldInit();
});
