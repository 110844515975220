/* global BX:writable */

const getCurrentUserId = () => {
    const userId = BX.message('USER_ID');

    return userId.length > 0 ? parseInt(userId, 10) : 0;
};

const lockScroll = () => {
    document.documentElement.classList.add('scroll-lock');
};

const unlockScroll = () => {
    document.documentElement.classList.remove('scroll-lock');
};

const ruFancybox = {
    CLOSE: 'Закрыть',
    NEXT: 'Следующий',
    PREV: 'Предыдущий',
    MODAL: 'Вы можете закрыть это модальное окно с помощью клавиши ESC',
    ERROR: 'Ошибка, попробуйте повторить позже',
    IMAGE_ERROR: 'Изображение не найдено',
    ELEMENT_NOT_FOUND: 'HTML элемент не найден',
    AJAX_NOT_FOUND: 'Ошибка загрузки AJAX: Не найдено',
    AJAX_FORBIDDEN: 'Ошибка загрузки AJAX: Запрещено',
    IFRAME_ERROR: 'Ошибка при загрузке фрейма',
    TOGGLEZOOM: 'Изменить масштабирование',
    TOGGLE1TO1: 'Изменить масштабирование',
    ITERATEZOOM: 'Изменить масштабирование',
    TOGGLE_ZOOM: 'Изменить масштабирование',
    TOGGLE_THUMBS: 'Миниатюры',
    TOGGLE_SLIDESHOW: 'Слайдшоу',
    TOGGLE_FULLSCREEN: 'Полноэкранный режим',
    DOWNLOAD: 'Загрузить',
};

export {getCurrentUserId, lockScroll, unlockScroll, ruFancybox};
