const TRANSPARENT_CLASS_NAME = 'header--transparent';

const headerElement = document.querySelector(`.${TRANSPARENT_CLASS_NAME}`);
const headerHeight = headerElement ? headerElement.offsetHeight : 0;

const switchTransparent = () => {
    headerElement && headerElement.classList.toggle(TRANSPARENT_CLASS_NAME, window.scrollY < headerHeight);
};

window.addEventListener('scroll', () => {
    switchTransparent();
});
