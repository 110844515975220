import {getMaskValidate} from './mask';

const getMaskClassName = (classList) => {
    const classes = [...classList];
    let maskClass;

    classes.forEach((className) => {
        if (className.includes('mask-')) {
            maskClass = className;
        }
    });

    return maskClass;
};

const checkFromMask = (classList, value) => {
    let result = true;
    const maskClass = getMaskClassName(classList);

    if (maskClass) {
        const validateFunction = getMaskValidate(maskClass);

        if (typeof validateFunction === 'function') {
            result = validateFunction(value);
        }
    }

    return result;
};

const fieldCompletionCheck = (fieldElement) => {
    let isValid = true;

    const errorClassName = `${fieldElement.classList[0]}--error`;

    if (fieldElement.value.length < 1) {
        fieldElement.classList.add(errorClassName);
        isValid = false;
    } else if (fieldElement.classList.value.includes('mask-')) {
        const maskPassed = checkFromMask(fieldElement.classList, fieldElement.value);

        if (!maskPassed) {
            fieldElement.classList.add(errorClassName);
            isValid = false;
        } else {
            fieldElement.classList.remove(errorClassName);
        }
    } else {
        fieldElement.classList.remove(errorClassName);
    }

    return isValid;
};

const fileFieldCompletionCheck = (fieldElement) => {
    let isValid = true;

    const errorClassName = 'file-field--error';
    const parentFieldElement = fieldElement.closest('.file-field');
    const fileList = fieldElement.files;

    if (fileList.length < 1) {
        parentFieldElement.classList.add(errorClassName);
        isValid = false;
    } else {
        parentFieldElement.classList.remove(errorClassName);
    }

    return isValid;
};

const validateValueFields = (fieldElements) => {
    const validResults = [];

    fieldElements.forEach((field) => {
        const isValid = fieldCompletionCheck(field);

        validResults.push(isValid);

        field.addEventListener('change', () => {
            fieldCompletionCheck(field);
        });
    });

    return validResults.every((value) => value === true);
};

const validateFileFields = (fileElements) => {
    const validResults = [];

    fileElements.forEach((element) => {
        validResults.push(fileFieldCompletionCheck(element));

        element.addEventListener('change', () => {
            fileFieldCompletionCheck(element);
        });
    });

    return validResults.every((value) => value === true);
};

export {validateValueFields, validateFileFields};
