import {lockButton, unlockButton, validateForm} from './form-utils/base';
import {api} from './api';

const showResult = (message) => {
    const messageElement = document.querySelector('.subscribe__title');

    if (messageElement) {
        messageElement.textContent = message;
    }
};

const formSubmitHandler = (evt) => {
    evt.preventDefault();

    const formElement = evt.target;

    if (!validateForm(formElement)) {
        return;
    }

    const submitButton = formElement.querySelector('button[type="submit"]');

    lockButton(submitButton, true);

    const formData = new FormData(formElement);

    api.post('news-subscribe/', {body: formData})
        .json()
        .then((response) => {
            showResult(response.message);

            formElement.remove();
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            showResult(errorJson.message);

            unlockButton(submitButton, true);
        });
};

document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector('form[name="subscribe-news"]');

    if (form) {
        form.addEventListener('submit', formSubmitHandler);
    }
});
