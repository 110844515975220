const openSearch = (searchElement) => {
    searchElement.classList.add('search--show');

    const autofocusField = searchElement.querySelector('.search__input');

    if (autofocusField && !autofocusField.value) {
        autofocusField.focus();
    }

    document.addEventListener('keydown', keydownHandler);
};

const closeSearch = (searchElement) => {
    searchElement.classList.remove('search--show');

    document.removeEventListener('keydown', keydownHandler);
};

function keydownHandler(evt) {
    if (evt.code === 'Escape') {
        const searchElement = document.querySelector('.search');

        if (searchElement) {
            closeSearch(searchElement);
        }
    }
}

const clickTriggerHandler = (searchElement) => {
    openSearch(searchElement);
};

const searchBlockInit = (searchElement) => {
    const overlayElement = searchElement.querySelector('.search__overlay');

    if (overlayElement) {
        overlayElement.addEventListener('click', () => {
            closeSearch(searchElement);
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    const searchElement = document.querySelector('.search');

    if (!searchElement) {
        return;
    }

    searchBlockInit(searchElement);

    const searchTriggerElements = document.querySelectorAll('.search-trigger');

    if (searchTriggerElements.length > 0) {
        searchTriggerElements.forEach((item) => {
            item.addEventListener('click', () => {
                clickTriggerHandler(searchElement);
            });
        });
    }
});
