import IMask from 'imask';

const masks = [
    {
        selector: '.mask-phone',
        options: {
            mask: '+{7} (000) 000-00-00',
        },
    },
    {
        selector: '.mask-number',
        options: {
            mask: Number,
        },
    },
    {
        selector: '.mask-inn',
        options: {
            mask: '0000000000[00]',
        },
    },
    {
        selector: '.mask-currency',
        options: {
            lazy: false,
            mask: 'num',
            blocks: {
                num: {
                    mask: Number,
                    thousandsSeparator: ' ',
                    signed: false,
                    scale: 2,
                    padFractionalZeros: true,
                    radix: ',',
                    mapToRadix: ['.'],
                },
            },
        },
    },
    {
        selector: '.mask-date',
        options: {
            mask: Date,
            autofix: 'pad',
        },
    },
];

const maskInit = (targetNode = false) => {
    masks.forEach((itemMask) => {
        const node = targetNode || document;
        const fields = node.querySelectorAll(itemMask.selector);

        fields.forEach((itemField) => {
            IMask(itemField, itemMask.options);
        });
    });
};

const maskValidate = {
    'mask-phone': (value) => {
        const pattern = new RegExp(/^\+7\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$/);
        return pattern.test(value);
    },
    'mask-inn': (value) => {
        const pattern = new RegExp(/^(\d{10}|\d{12})$/);
        return pattern.test(value);
    },
};

const getMaskValidate = (maskName) => {
    if (Object.prototype.hasOwnProperty.call(maskValidate, maskName)) {
        return maskValidate[maskName];
    }

    return false;
};

export {maskInit, getMaskValidate};
