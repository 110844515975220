import {api} from '../api';
import {lockScroll, unlockScroll} from '../utils';

const LINK_CLASS_NAME = 'catalog-nav__link';
const OPENED_CLASS_NAME = 'catalog-nav__link--open';
const HAVE_PARENT_CLASS_NAME = 'catalog-nav__link--parent';
const COLUMN_CLASS_NAME = 'catalog-nav__column';
const HIDDEN_COLUMN_CLASS_NAME = 'catalog-nav__column--hidden';
const LOADER_CLASS_NAME = 'catalog-nav__loader';
const HIDDEN_LOADER_CLASS_NAME = 'catalog-nav__loader--hidden';

const openerElement = document.querySelector('.catalog-trigger');
const catalogNavElement = document.querySelector('.catalog-nav');

const backDropHandler = ({currentTarget, target}) => {
    const isClickedOnBackDrop = target === currentTarget;

    if (isClickedOnBackDrop || target.classList.contains('catalog-nav__columns')) {
        catalogNavElement.close();
    }
};

const removeOpenClassByParent = (parent) => {
    const openedElements = parent.querySelectorAll(`.${OPENED_CLASS_NAME}`);

    openedElements.forEach((item) => {
        item.classList.remove(OPENED_CLASS_NAME);
    });
};

const openNextItemsColumn = (currentItem) => {
    const columnElement = currentItem.closest(`.${COLUMN_CLASS_NAME}`);
    const nextColumnElement = columnElement.nextElementSibling;

    if (!nextColumnElement) {
        return false;
    }

    const listElement = nextColumnElement.querySelector('.catalog-nav__list');

    if (listElement) {
        listElement.remove();
    }

    const loaderElement = nextColumnElement.querySelector('.catalog-nav__loader');

    if (loaderElement) {
        loaderElement.classList.remove(HIDDEN_LOADER_CLASS_NAME);
    }

    nextColumnElement.classList.remove(HIDDEN_COLUMN_CLASS_NAME);

    return nextColumnElement;
};

const closeNextItemsColumns = (currentItem) => {
    const columnElement = currentItem.closest(`.${COLUMN_CLASS_NAME}`);
    const nextColumnElement = columnElement.nextElementSibling;

    if (!nextColumnElement) {
        return;
    }

    nextColumnElement.classList.add(HIDDEN_COLUMN_CLASS_NAME);

    closeNextItemsColumns(nextColumnElement);

    removeOpenClassByParent(columnElement);
};

const hideLoaderInColumn = (columnElement) => {
    const loaderElement = columnElement.querySelector(`.${LOADER_CLASS_NAME}`);

    if (loaderElement) {
        loaderElement.classList.add(HIDDEN_LOADER_CLASS_NAME);
    }
};

const renderItems = (columnElement, data) => {
    hideLoaderInColumn(columnElement);

    if (data.length <= 0) {
        return;
    }

    let listItems = '';

    data.forEach((item) => {
        listItems += `
            <li class="catalog-nav__item">
                <a class="catalog-nav__link${item.isParent ? ' catalog-nav__link--parent' : ''}" href="${
                    item.url
                }" data-section-id="${item.id}">${item.name}</a>
            </li>
        `;
    });

    columnElement.insertAdjacentHTML('afterbegin', `<ul class="catalog-nav__list">${listItems}</ul>`);

    navItemHoverInit(columnElement);
};

let isPending = false;
let timeoutId = 0;

const navItemHoverHandler = ({target}, parent) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
        const sectionId = parseInt(target.dataset.sectionId, 10);

        if (target.classList.contains(OPENED_CLASS_NAME) || isPending || sectionId <= 0) {
            return;
        }

        closeNextItemsColumns(target);

        if (!target.classList.contains(HAVE_PARENT_CLASS_NAME)) {
            return;
        }

        isPending = true;

        removeOpenClassByParent(parent);

        target.classList.add(OPENED_CLASS_NAME);

        const nextColumnElement = openNextItemsColumn(target);

        api.get(`catalog/section/${sectionId}/subsections/`)
            .json()
            .then((response) => {
                isPending = false;

                renderItems(nextColumnElement, response.subsections);
            })
            .catch(() => {
                isPending = false;
            });
    }, 500);
};

function navItemHoverInit(targetElement) {
    const navItemElements = targetElement.querySelectorAll(`.${LINK_CLASS_NAME}:not(.${OPENED_CLASS_NAME})`);

    if (navItemElements.length > 0) {
        navItemElements.forEach((item) => {
            item.addEventListener('mouseenter', (evt) => {
                navItemHoverHandler(evt, targetElement);
            });
        });
    }
}

const navItemHoverDestroy = (targetElement) => {
    const navItemElements = targetElement.querySelectorAll(`.${LINK_CLASS_NAME}`);

    if (navItemElements.length > 0) {
        navItemElements.forEach((item) => {
            item.removeEventListener('mouseenter', navItemHoverHandler);
        });
    }
};

const openerClickSetTimeoutHandler = () => {
    catalogNavElement.addEventListener('click', backDropHandler);

    catalogNavElement.addEventListener('close', () => {
        catalogNavElement.removeEventListener('click', backDropHandler);
        unlockScroll();

        navItemHoverDestroy(catalogNavElement);
    });
};

const openerClickHandler = () => {
    catalogNavElement.showModal();
    lockScroll();

    navItemHoverInit(catalogNavElement);

    setTimeout(openerClickSetTimeoutHandler, 100);
};

if (openerElement && window.innerWidth >= 1024) {
    openerElement.addEventListener('click', openerClickHandler);
}
