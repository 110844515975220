/* global BX:writable */

import ky from 'ky';

const BASE_PATH = '/api/v1';

const api = ky.create({
    headers: {
        'Session-Id': BX.bitrix_sessid(),
    },
    prefixUrl: BASE_PATH,
});

const getBasePath = () => BASE_PATH;

export {api, getBasePath};
