/* global BX:writable */

import {api} from './api';

const initOrderHandler = (order) => {
    const savedAddress = order.querySelector('[data-entity="saved_address"]');

    if (savedAddress) {
        actualizeSavedAddress();
    }
};

const setSavedAddress = (data) => {
    const savedAddress = document.querySelector('[data-entity="saved_address"]');
    const select = savedAddress.querySelector('.order__input');

    if (!select.querySelector('[data-entity="option-trigger"]')) {
        data.forEach((element, index) => {
            const option = document.createElement('option');

            if (index === 0) {
                option.dataset.entity = 'option-trigger';
            }

            option.value = index;
            option.text = element.city + ' ' + element.street + ' ' + element.house + ' ' + element.apartment;

            select.add(option);
        });
    }

    select.addEventListener('change', function (trigger) {
        const selectAddress = data[trigger.target.value];
        const city = document.querySelector('[data-entity="city"]');
        const street = document.querySelector('[data-entity="street"]');
        const house = document.querySelector('[data-entity="house"]');
        const apartment = document.querySelector('[data-entity="apartment"]');

        city.value = selectAddress.code;
        street.value = selectAddress.street;
        house.value = selectAddress.house;
        apartment.value = selectAddress.apartment;
    });
};

const actualizeSavedAddress = () => {
    api.get('delivery-address/')
        .json()
        .then((response) => {
            setSavedAddress(response.savedAddress);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

document.addEventListener('DOMContentLoaded', () => {
    const order = document.querySelector('[data-entity="order"]');

    if (order) {
        initOrderHandler(order);
    }

    BX.addCustomEvent('onAjaxSuccess', function () {
        if (order) {
            initOrderHandler(order);
        }
    });
});
