const clickListTriggerHandler = (evt) => {
    const listElement = evt.currentTarget.nextElementSibling;

    if (listElement) {
        listElement.classList.toggle('two-level-list__sub-list--open');
    }
};

document.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth < 1024) {
        const listTriggerElements = document.querySelectorAll('.two-level-list__sub-list-trigger');

        if (listTriggerElements.length > 0) {
            listTriggerElements.forEach((item) => {
                item.addEventListener('click', clickListTriggerHandler);
            });
        }
    }
});
