import {lockButton, unlockButton, validateForm} from './form-utils/base';
import {api} from './api';

const formSubmitHandler = (evt) => {
    evt.preventDefault();

    const formElement = evt.target;

    if (!validateForm(formElement)) {
        return;
    }

    const submitButton = formElement.querySelector('button[type="submit"]');

    lockButton(submitButton);

    const formData = new FormData(formElement);
    const requestBody = JSON.stringify(Object.fromEntries(formData));
    const userType = formData.get('userType');

    api.patch(`user/${userType}/`, {
        body: requestBody,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .json()
        .then(() => {
            setTimeout(() => {
                document.location.reload();
            }, 500);
        })
        .catch(async () => {
            unlockButton(submitButton);
        });
};

document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector('form[name="profile"]');

    if (form) {
        form.addEventListener('submit', formSubmitHandler);
    }
});
