/* global BX:writable */

import {api} from './api';

const getAmountField = (cardElement) => {
    return cardElement.querySelector('[data-entity="amount-field"]');
};

const getProductIdByCard = (cardElement) => {
    return parseInt(cardElement.dataset.productCardId, 10);
};

const switchDisplayBuyBlock = (cardElement, isHideAmount = false) => {
    const buyTriggerElement = cardElement.querySelector('[data-entity="add-to-cart"]');

    if (buyTriggerElement) {
        buyTriggerElement.classList.toggle('hidden', !isHideAmount);
    }

    const amountWrapperElement = cardElement.querySelector('[data-entity="amount-wrapper"]');

    if (amountWrapperElement) {
        amountWrapperElement.classList.toggle('hidden', isHideAmount);
    }
};

const deleteFromCart = (cardElement) => {
    const productId = getProductIdByCard(cardElement);

    if (!productId) {
        return;
    }

    api.delete(`cart/product/${productId}/`)
        .json()
        .then(() => {
            switchDisplayBuyBlock(cardElement, true);

            BX.onCustomEvent('OnBasketChange');
        })
        .catch(async (error) => {
            const errorJson = error.response && (await error.response.json());

            errorJson && console.error(errorJson.message);
        });
};

const addToCart = (cardElement) => {
    const fieldElement = getAmountField(cardElement);

    if (!fieldElement) {
        return;
    }

    checkAmountBlock(cardElement);

    const productId = getProductIdByCard(cardElement);

    if (!productId) {
        return;
    }

    let amount = parseInt(fieldElement.value, 10);

    api.post(`cart/product/${productId}/`, {json: {amount: amount}})
        .json()
        .then(() => {
            switchDisplayBuyBlock(cardElement);

            BX.onCustomEvent('OnBasketChange');
        })
        .catch(async (error) => {
            const errorJson = error.response && (await error.response.json());

            errorJson && console.error(errorJson.message);
        });
};

const clickAmountTriggerHandler = (evt, cardElement) => {
    const triggerValue = evt.currentTarget.value;
    const fieldElement = getAmountField(cardElement);

    if (!fieldElement) {
        return;
    }

    let amount = parseInt(fieldElement.value, 10);

    switch (triggerValue) {
        case 'reduce': {
            amount = amount - 1;

            if (amount >= 1) {
                fieldElement.value = amount;

                addToCart(cardElement);
            } else {
                switchDisplayBuyBlock(cardElement, true);

                deleteFromCart(cardElement);
            }

            break;
        }
        case 'increase': {
            const maxAmount = fieldElement.max;

            amount = amount + 1;

            if (amount <= maxAmount) {
                fieldElement.value = amount;

                addToCart(cardElement);
            }

            break;
        }
    }
};

const changeAmountFieldHandler = (cardElement) => {
    addToCart(cardElement);
};

const initAmountHandler = (cardElement) => {
    const buyTriggerElement = cardElement.querySelector('[data-entity="add-to-cart"]');

    if (buyTriggerElement) {
        buyTriggerElement.addEventListener('click', () => {
            addToCart(cardElement);

            switchDisplayBuyBlock(cardElement);
        });
    }

    const amountTriggerElements = cardElement.querySelectorAll('[data-entity="amount-trigger"]');

    if (amountTriggerElements.length > 0) {
        amountTriggerElements.forEach((item) => {
            item.addEventListener('click', (evt) => {
                clickAmountTriggerHandler(evt, cardElement);
            });
        });
    }

    const amountFieldElement = cardElement.querySelector('[data-entity="amount-field"]');

    if (amountFieldElement) {
        amountFieldElement.addEventListener('change', () => {
            changeAmountFieldHandler(cardElement);
        });
    }
};

const switchActiveAmountTrigger = (amountTriggerElement, isDisable = false) => {
    if (amountTriggerElement) {
        amountTriggerElement.disabled = isDisable;
    }
};

const checkAmountBlock = (cardElement) => {
    const fieldElement = getAmountField(cardElement);

    if (!fieldElement) {
        return;
    }

    let amount = parseInt(fieldElement.value, 10);
    const maxAmount = parseInt(fieldElement.max, 10);

    const increaseTriggerElement = cardElement.querySelector('[data-entity="amount-trigger"][value="increase"]');

    if (amount < 1) {
        fieldElement.value = 1;
    } else if (amount >= maxAmount) {
        fieldElement.value = maxAmount;
        switchActiveAmountTrigger(increaseTriggerElement, true);
    } else {
        switchActiveAmountTrigger(increaseTriggerElement, false);
    }
};

const setProductAmount = (productId, amount) => {
    const cardElement = document.querySelector(`[data-product-card-id="${productId}"]`);

    if (cardElement) {
        const fieldElement = getAmountField(cardElement);

        if (fieldElement) {
            const maxAmount = parseInt(fieldElement.max, 10);

            fieldElement.value = amount > maxAmount ? maxAmount : amount;

            switchDisplayBuyBlock(cardElement);

            checkAmountBlock(cardElement);
        }
    }
};

const actualizeAmount = () => {
    api.get('cart/amount/')
        .json()
        .then((response) => {
            if (response.products.length > 0) {
                response.products.forEach((item) => {
                    setProductAmount(item.id, item.amount);
                });
            }
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

export {actualizeAmount, initAmountHandler};
