/* eslint no-global-assign: "error" */
/* global ymaps:writable */

ymaps = window.ymaps;

if (ymaps) {
    ymaps.ready(() => {
        const zoomControl = new ymaps.control.ZoomControl({
            options: {
                float: 'none',
                position: {
                    bottom: '50px',
                    right: '30px',
                },
            },
        });

        const map = new ymaps.Map('company-map', {
            center: [48.49837, 135.038769],
            zoom: 16,
            controls: [zoomControl],
        });

        map.behaviors.disable('scrollZoom');

        const HintLayout = ymaps.templateLayoutFactory.createClass(
            "<div class='map-hint'>ул.Тихоокеанская, 73, лит Б1</div>",
            {
                getShape() {
                    const el = this.getElement();
                    let result = null;

                    if (el) {
                        const {firstChild} = el;

                        result = new ymaps.shape.Rectangle(
                            new ymaps.geometry.pixel.Rectangle([
                                [0, 0],
                                [firstChild.offsetWidth, firstChild.offsetHeight],
                            ])
                        );
                    }

                    return result;
                },
            }
        );

        const placemark = new ymaps.Placemark(
            [48.49837, 135.038769],
            {
                address: 'ул.Тихоокеанская, 73, лит Б1',
            },
            {
                hintLayout: HintLayout,
                iconLayout: 'default#image',
                iconImageHref: '/local/templates/supplier/assets/src/images/contacts/map-pin.svg',
                iconImageSize: [62, 79],
                iconImageOffset: [-31, -79],
            }
        );

        map.geoObjects.add(placemark);
    });
}
