import {validateValueFields, validateFileFields} from './validate';

const validateForm = (formElement) => {
    const fieldElements = formElement.querySelectorAll(
        'input[required]:not([type="file"]), select[required], textarea[required]'
    );
    const fileElements = formElement.querySelectorAll('input[type="file"][required]');

    const isValidateFields = validateValueFields(fieldElements);
    const isValidateFileFields = validateFileFields(fileElements);

    return isValidateFields && isValidateFileFields;
};

const lockButton = (button, isSkipClassChange = false) => {
    button.disabled = true;

    if (!isSkipClassChange) {
        button.classList.add('btn--loading');
    }
};

const unlockButton = (button, isSkipClassChange = false) => {
    button.disabled = false;

    if (!isSkipClassChange) {
        button.classList.remove('btn--loading');
    }
};

export {validateForm, lockButton, unlockButton};
