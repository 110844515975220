import {api} from './api';

const getOrderDetailTriggerData = (evt) => {
    const triggerElement = evt.currentTarget;
    const orderId = triggerElement.dataset.orderId;
    const idPrefix = triggerElement.dataset.orderPrefix;
    const productsListElement = document.querySelector(`#order-products-${idPrefix}-${orderId}`);
    return {triggerElement, orderId, idPrefix, productsListElement};
};

const onOrderDetailRequest = (evt) => {
    const {triggerElement, orderId, productsListElement} = getOrderDetailTriggerData(evt);

    api.get(`orders/${orderId}`)
        .text()
        .then((response) => {
            if (productsListElement) {
                productsListElement.innerHTML = response;
                productsListElement.scrollIntoView();
            }
            triggerElement.classList.toggle('order-table__products-btn--active', true);
            triggerElement.removeEventListener('click', onOrderDetailRequest);
            triggerElement.addEventListener('click', onOrderDetailClose);
        })
        .catch(async () => {});
};

const onOrderDetailOpen = (evt) => {
    const {triggerElement, productsListElement} = getOrderDetailTriggerData(evt);
    if (productsListElement) {
        productsListElement.classList.toggle('order-products__list--hidden', false);
        productsListElement.scrollIntoView();
    }
    triggerElement.classList.toggle('order-table__products-btn--active', true);
    triggerElement.removeEventListener('click', onOrderDetailOpen);
    triggerElement.addEventListener('click', onOrderDetailClose);
};

const onOrderDetailClose = (evt) => {
    const {triggerElement, productsListElement} = getOrderDetailTriggerData(evt);
    if (productsListElement) {
        productsListElement.classList.toggle('order-products__list--hidden', true);
    }
    triggerElement.classList.toggle('order-table__products-btn--active', false);
    triggerElement.removeEventListener('click', onOrderDetailClose);
    triggerElement.addEventListener('click', onOrderDetailOpen);
};

document.addEventListener('DOMContentLoaded', () => {
    const orderDetailTriggers = document.querySelectorAll('[data-order-detail]');

    orderDetailTriggers.forEach((trigger) => {
        trigger.addEventListener('click', onOrderDetailRequest);
    });
});
