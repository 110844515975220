import {dialogTriggerClickHandler} from './base';
import {lockButton, unlockButton} from '../form-utils/base';
import {api} from '../api';
import {showAlert} from '../form-utils/alert';

const cancelSubmitHandler = (evt) => {
    const confirmElement = evt.target.closest('#cancelConfirm');

    const submitButton = evt.currentTarget;

    lockButton(submitButton);

    const orderId = submitButton.dataset.orderId;

    api.patch(`orders/cancel/${orderId}`)
        .json()
        .then((response) => {
            showAlert(confirmElement, 'beforebegin', response.message, 'success');
            setTimeout(() => {
                document.location.reload();
            }, 500);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            showAlert(confirmElement, 'beforebegin', errorJson.message, 'error');

            unlockButton(submitButton);
        });
};

const cancelSubmitDialogInit = (dialogElement) => {
    const btn = dialogElement.querySelector('#cancelOrderBtn');

    if (btn) {
        btn.addEventListener('click', cancelSubmitHandler);
    }
};

const displaysDialogInit = (dialogElement) => {
    const triggerElements = document.querySelectorAll('.cancel-order-dialog-trigger');
    const cancelOrderElement = dialogElement.querySelector('#cancelOrderBtn');

    if (triggerElements.length > 0) {
        triggerElements.forEach((triggerElement) => {
            triggerElement.addEventListener('click', (evt) => {
                if (cancelOrderElement) cancelOrderElement.dataset.orderId = triggerElement.dataset.orderId;
                dialogTriggerClickHandler(evt, dialogElement);
            });
        });
    }
};

const cancelOrderDialogInit = () => {
    const dialogElement = document.querySelector('#cancelOrderDialog');

    if (dialogElement) {
        displaysDialogInit(dialogElement);
        cancelSubmitDialogInit(dialogElement);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    cancelOrderDialogInit();
});
