import EmblaCarousel from 'embla-carousel';
import {Fancybox} from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import {ruFancybox} from './utils';
import {api} from './api';

const addImageThumbClickHandlers = (emblaApiMain, emblaApiThumb) => {
    const slidesThumbs = emblaApiThumb.slideNodes();

    const scrollToIndex = slidesThumbs.map((_, index) => () => emblaApiMain.scrollTo(index));

    slidesThumbs.forEach((slideNode, index) => {
        slideNode.addEventListener('click', scrollToIndex[index], false);
    });

    return () => {
        slidesThumbs.forEach((slideNode, index) => {
            slideNode.removeEventListener('click', scrollToIndex[index], false);
        });
    };
};

const addToggleImageThumbActive = (emblaApiMain, emblaApiThumb) => {
    const slidesThumbs = emblaApiThumb.slideNodes();

    const toggleThumbState = () => {
        emblaApiThumb.scrollTo(emblaApiMain.selectedScrollSnap());
        const previous = emblaApiMain.previousScrollSnap();
        const selected = emblaApiMain.selectedScrollSnap();
        slidesThumbs[previous].classList.remove('product-detail__image-thumb--active');
        slidesThumbs[selected].classList.add('product-detail__image-thumb--active');
    };

    emblaApiMain.on('select', toggleThumbState);
    emblaApiThumb.on('init', toggleThumbState);

    return () => {
        const selected = emblaApiMain.selectedScrollSnap();

        slidesThumbs[selected].classList.remove('product-detail__image-thumb--active');
    };
};

const addDotClickHandlers = (emblaApi, dotsNode) => {
    let dotNodes = [];

    const addDotWithClickHandlers = () => {
        dotsNode.innerHTML = emblaApi
            .scrollSnapList()
            .map(
                () =>
                    '<button class="product-detail__image-slider-dot" type="button" title="Переключить"><span class="visually-hidden">Переключить</span></button>'
            )
            .join('');

        dotNodes = Array.from(dotsNode.querySelectorAll('.product-detail__image-slider-dot'));

        dotNodes.forEach((dotNode, index) => {
            dotNode.addEventListener('click', () => emblaApi.scrollTo(index), false);
        });
    };

    const toggleDotActive = () => {
        const previous = emblaApi.previousScrollSnap();
        const selected = emblaApi.selectedScrollSnap();
        dotNodes[previous].classList.remove('product-detail__image-slider-dot--active');
        dotNodes[selected].classList.add('product-detail__image-slider-dot--active');
    };

    emblaApi
        .on('init', addDotWithClickHandlers)
        .on('reInit', addDotWithClickHandlers)
        .on('init', toggleDotActive)
        .on('reInit', toggleDotActive)
        .on('select', toggleDotActive);

    return () => {
        dotsNode.innerHTML = '';
    };
};

const OPTIONS = {};

const OPTIONS_THUMBS = {
    containScroll: false,
    dragFree: false,
    watchDrag: false,
};

const sliderInit = () => {
    const sliderWrapperElement = document.querySelector('.product-detail__image-slider-wrapper');

    if (sliderWrapperElement) {
        const imageThumbsWrapperElement = document.querySelector('.product-detail__image-thumbs-wrapper');

        const emblaApiMain = EmblaCarousel(sliderWrapperElement, OPTIONS);
        const emblaApiThumb = EmblaCarousel(imageThumbsWrapperElement, OPTIONS_THUMBS);

        const removeImageThumbClickHandlers = addImageThumbClickHandlers(emblaApiMain, emblaApiThumb);

        const removeToggleImageThumbActive = addToggleImageThumbActive(emblaApiMain, emblaApiThumb);

        emblaApiMain.on('destroy', removeImageThumbClickHandlers).on('destroy', removeToggleImageThumbActive);

        emblaApiThumb.on('destroy', removeImageThumbClickHandlers).on('destroy', removeToggleImageThumbActive);

        if (window.innerWidth < 1024) {
            const dotsElement = document.querySelector('.product-detail__image-slider-dots');

            addDotClickHandlers(emblaApiMain, dotsElement);
        }
    }
};

const fancyboxInit = () => {
    Fancybox.bind('[data-fancybox="product-slider"]', {
        l10n: ruFancybox,
        Images: {
            zoom: false,
        },
        hideScrollbar: false,
        idle: 10000,
    });
};

const updateStoreAmountElements = (totalAmount) => {
    const totalAmountElement = document.querySelector('[data-entity="total-amount"]');

    if (totalAmountElement) {
        totalAmountElement.innerText = totalAmount.available ?? 0;
    }

    const totalRequestAmountElement = document.querySelector('[data-entity="total-request-amount"]');

    if (totalRequestAmountElement) {
        let requestAmountHtml = '';

        if (totalAmount.onRequest && totalAmount.onRequest > 0) {
            requestAmountHtml = `<dt>При заказе 10-14 дней</dt><dd>${totalAmount.onRequest}</dd>`;
        } else {
            requestAmountHtml = `<dt>Под заказ</dt><dd>0</dd>`;
        }

        totalRequestAmountElement.innerHTML = requestAmountHtml;
    }
};

const fetchStoreAmount = (productId) => {
    let totalAmount = {
        available: 0,
        onRequest: 0,
    };

    api.get(`store/product/${productId}/amount/`)
        .json()
        .then((response) => {
            if (response.length > 0) {
                response.forEach((item) => {
                    item.isFromSupplier
                        ? (totalAmount.onRequest += item.amount)
                        : (totalAmount.available += item.amount);
                });
            }

            updateStoreAmountElements(totalAmount);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

const storeAmountInit = () => {
    const wrapperElement = document.querySelector('[data-entity="store-amount-wrapper"]');

    if (wrapperElement) {
        const productId = document.querySelector('[data-product-card-id]').dataset.productCardId;

        fetchStoreAmount(productId);
    }
};

const characteristicsBlockInit = () => {
    const characteristicsTriggerElement = document.querySelector('.product-detail__characteristics-trigger');
    const characteristicsListElement = document.querySelector('.product-detail__characteristics-list');

    if (characteristicsTriggerElement && characteristicsListElement) {
        characteristicsTriggerElement.addEventListener('click', () => {
            characteristicsTriggerElement.classList.toggle('product-detail__characteristics-trigger--active');

            characteristicsListElement.classList.toggle('product-detail__characteristics-list--active');
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    sliderInit();
    fancyboxInit();
    characteristicsBlockInit();

    setTimeout(() => {
        storeAmountInit();
    }, 200);
});
