let clickByOutsideHandlerFunction;

const clickByOutsideHandler = (evt, dropDownTriggerElement, dropDownElement) => {
    const parentElement = dropDownElement.parentElement;

    if (!parentElement.contains(evt.target)) {
        closeDropDown(dropDownTriggerElement, dropDownElement);
    }
};

const isDesktopFlying = (triggerElement) => {
    return (
        window.innerWidth >= 1024 &&
        triggerElement.parentElement.classList.contains('catalog__drop-down--desktop-flying')
    );
};

const openDropDown = (triggerElement, dropDownElement) => {
    triggerElement.classList.add('catalog__drop-down-trigger--triggered');
    dropDownElement.classList.add('catalog__drop-down-target--open');

    triggerElement.title = 'Свернуть';

    if (isDesktopFlying(triggerElement)) {
        setTimeout(() => {
            document.addEventListener(
                'click',
                (clickByOutsideHandlerFunction = function (evt) {
                    clickByOutsideHandler(evt, triggerElement, dropDownElement);
                })
            );
        }, 100);
    }
};

const closeDropDown = (triggerElement, dropDownElement) => {
    triggerElement.classList.remove('catalog__drop-down-trigger--triggered');
    dropDownElement.classList.remove('catalog__drop-down-target--open');

    triggerElement.title = 'Раскрыть';

    if (isDesktopFlying(triggerElement)) {
        document.removeEventListener('click', clickByOutsideHandlerFunction);
    }
};

const clickDropDownTriggerHandler = (evt) => {
    const dropDownElement = evt.currentTarget.parentElement.querySelector('.catalog__drop-down-target');

    if (dropDownElement) {
        const isOpen = dropDownElement.classList.contains('catalog__drop-down-target--open');

        if (isOpen) {
            closeDropDown(evt.currentTarget, dropDownElement);
        } else {
            openDropDown(evt.currentTarget, dropDownElement);
        }
    }
};

document.addEventListener('DOMContentLoaded', () => {
    const dropDownTriggerElements = document.querySelectorAll('.catalog__drop-down-trigger');

    if (dropDownTriggerElements.length > 0) {
        dropDownTriggerElements.forEach((item) => {
            item.addEventListener('click', clickDropDownTriggerHandler);
        });
    }
});
