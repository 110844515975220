import {lockButton, unlockButton, validateForm} from './form-utils/base';
import {api} from './api';

const formSubmitHandler = (evt) => {
    evt.preventDefault();

    const formElement = evt.target;

    if (!validateForm(formElement)) {
        return;
    }

    const submitButton = formElement.querySelector('button[type="submit"]');

    lockButton(submitButton);

    const formData = new FormData(formElement);

    api.post('feedback/', {body: formData})
        .json()
        .then(() => {
            setTimeout(() => {
                document.location.reload();
            }, 500);
        })
        .catch(async () => {
            unlockButton(submitButton);
        });
};

document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector('form[name="feedback"]');

    if (form) {
        form.addEventListener('submit', formSubmitHandler);
    }
});
