/* global BX:writable */

import './header';
import './embla-slider';
import './cookies-accept';
import './dialogs/catalog-nav';
import './company-map';
import './form-utils/switch-field';
import './two-level-list';
import './dialogs/registration';
import './dialogs/auth';
import './dialogs/recovery';
import './dialogs/add-address';
import './dialogs/edit-address';
import './dialogs/delete-address';
import './dialogs/cancel-order';
import './dialogs/clear-favorites';
import './catalog-drop-down';
import './product-card';
import './news-subscribe';
import './search';
import './product-detail';
import './switch-tabs';
import './cart';
import './feedback';
import './profile';
import './order-table';
import './order-saved-address';
import './favorite-count';
import './product-favorite';

import {maskInit} from './form-utils/mask';

document.addEventListener('DOMContentLoaded', () => {
    maskInit();

    BX.addCustomEvent('onAjaxSuccess', function () {
        maskInit();
    });
});
