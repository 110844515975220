const removeAlert = () => {
    const alertElements = document.querySelectorAll('.alert-wrap:not(.alert-wrap--static)');

    if (alertElements.length) {
        alertElements.forEach((item) => {
            item.remove();
        });
    }
};

const showAlert = (insertPlace, position, message, type) => {
    removeAlert();

    const data = `<div class="alert-wrap"><div class="alert alert--${type}">${message}</div></div>`;

    insertPlace.insertAdjacentHTML(position, data);
};

export {removeAlert, showAlert};
