import {dialogTriggerClickHandler} from './base';
import {lockButton, unlockButton} from '../form-utils/base';
import {api} from '../api';
import {showAlert} from '../form-utils/alert';

const deleteSubmitHandler = (evt) => {
    evt.preventDefault();

    const confirmElement = evt.target.closest('#deleteConfirm');

    const submitButton = confirmElement.querySelector('#deleteAddressBtn');

    lockButton(submitButton);

    const addressId = submitButton.dataset.addressId;

    api.delete(`delivery-address/${addressId}`)
        .json()
        .then((response) => {
            showAlert(confirmElement, 'beforebegin', response.message, 'success');
            setTimeout(() => {
                document.location.reload();
            }, 500);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            showAlert(confirmElement, 'beforebegin', errorJson.message, 'error');

            unlockButton(submitButton);
        });
};

const deleteSubmitDialogInit = (dialogElement) => {
    const btn = dialogElement.querySelector('#deleteAddressBtn');

    if (btn) {
        btn.addEventListener('click', deleteSubmitHandler);
    }
};

const displaysDialogInit = (dialogElement) => {
    const triggerElements = document.querySelectorAll('.delete-address-dialog-trigger');
    const deleteAddressElement = dialogElement.querySelector('#deleteAddressBtn');

    if (triggerElements.length > 0) {
        triggerElements.forEach((triggerElement) => {
            triggerElement.addEventListener('click', (evt) => {
                if (deleteAddressElement) deleteAddressElement.dataset.addressId = triggerElement.dataset.addressId;
                dialogTriggerClickHandler(evt, dialogElement);
            });
        });
    }
};

const deleteAddressDialogInit = () => {
    const dialogElement = document.querySelector('#deleteAddress');

    if (dialogElement) {
        displaysDialogInit(dialogElement);
        deleteSubmitDialogInit(dialogElement);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    deleteAddressDialogInit();
});
