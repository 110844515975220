import {dialogTriggerClickHandler} from './base';
import {lockButton, unlockButton, validateForm} from '../form-utils/base';
import {api} from '../api';
import {showAlert} from '../form-utils/alert';

const formSubmitHandler = (evt) => {
    evt.preventDefault();

    const formElement = evt.target;

    if (!validateForm(formElement)) {
        return;
    }

    const submitButton = formElement.querySelector('button[type="submit"]');

    lockButton(submitButton);

    const formData = new FormData(formElement);

    api.post('user/auth/', {body: formData})
        .json()
        .then((response) => {
            showAlert(formElement, 'beforebegin', response.message, 'success');

            formElement.remove();

            setTimeout(() => {
                document.location.reload();
            }, 500);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            showAlert(formElement, 'beforebegin', errorJson.message, 'error');

            unlockButton(submitButton);
        });
};

const formSubmitDialogInit = (dialogElement) => {
    const form = dialogElement.querySelector('form[name="auth"]');

    if (form) {
        form.addEventListener('submit', formSubmitHandler);
    }
};

const displaysDialogInit = (dialogElement) => {
    const triggerElements = document.querySelectorAll('.auth-dialog-trigger');

    if (triggerElements.length > 0) {
        triggerElements.forEach((triggerElement) => {
            triggerElement.addEventListener('click', (evt) => {
                dialogTriggerClickHandler(evt, dialogElement);
            });
        });
    }
};
const authDialogInit = () => {
    const dialogElement = document.querySelector('#authForm');

    if (dialogElement) {
        displaysDialogInit(dialogElement);
        formSubmitDialogInit(dialogElement);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    authDialogInit();
});
