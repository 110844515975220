const switchTabsActive = (trigger) => {
    const tabElements = document.querySelectorAll('.switch-tabs__trigger');

    tabElements.forEach((item) => {
        if (item === trigger) {
            item.classList.add('switch-tabs__trigger--active');
        } else {
            item.classList.remove('switch-tabs__trigger--active');
        }
    });
};

const switchTabsContentVisibility = (targetId) => {
    if (targetId.length <= 0) {
        return;
    }

    const targetElement = document.querySelector(`[data-target-id=${targetId}]`);

    if (targetElement) {
        const tabContentElements = document.querySelectorAll('.switch-tabs__target');

        tabContentElements.forEach((item) => {
            if (item === targetElement) {
                item.classList.add('switch-tabs__target--active');
            } else {
                item.classList.remove('switch-tabs__target--active');
            }
        });
    }
};

const switchTabsInit = () => {
    const triggerElements = document.querySelectorAll('.switch-tabs__trigger');

    if (triggerElements.length > 0) {
        triggerElements.forEach((item) => {
            item.addEventListener('click', (evt) => {
                const targetBlock = evt.target.dataset.targetFor;

                if (targetBlock.length > 0) {
                    switchTabsActive(item);
                    switchTabsContentVisibility(targetBlock);
                }
            });
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    switchTabsInit();
});
