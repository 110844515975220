import {dialogTriggerClickHandler} from './base';
import {lockButton, unlockButton, validateForm} from '../form-utils/base';
import {api} from '../api';
import {showAlert} from '../form-utils/alert';

const formSubmitHandler = (evt) => {
    evt.preventDefault();

    const formElement = evt.target;

    if (!validateForm(formElement)) {
        return;
    }

    const submitButton = formElement.querySelector('button[type="submit"]');

    lockButton(submitButton);

    const formData = new FormData(formElement);
    const requestBody = JSON.stringify(Object.fromEntries(formData));

    const addressId = formData.get('id');

    api.patch(`delivery-address/${addressId}`, {
        body: requestBody,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .json()
        .then((response) => {
            showAlert(formElement, 'beforebegin', response.message, 'success');
            setTimeout(() => {
                document.location.reload();
            }, 500);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            showAlert(formElement, 'beforebegin', errorJson.message, 'error');

            unlockButton(submitButton);
        });
};

const editButtonClickHandler = (triggerElement, dialogElement) => {
    const addressFields = JSON.parse(triggerElement.dataset.addressJson);
    const formElement = dialogElement.querySelector('form[name="edit-address"]');
    const inputElements = formElement.querySelectorAll('input.filled');

    if (inputElements.length > 0) {
        inputElements.forEach((inputElement) => {
            inputElement.value = addressFields[inputElement.name];
        });
    }
};

const formSubmitDialogInit = (dialogElement) => {
    const form = dialogElement.querySelector('form[name="edit-address"]');

    if (form) {
        form.addEventListener('submit', formSubmitHandler);
    }
};

const displaysDialogInit = (dialogElement) => {
    const triggerElements = document.querySelectorAll('.edit-address-dialog-trigger');
    const addressIdElement = dialogElement.querySelector('input[name="addressId"]');

    if (triggerElements.length > 0) {
        triggerElements.forEach((triggerElement) => {
            triggerElement.addEventListener('click', (evt) => {
                if (addressIdElement) addressIdElement.value = triggerElement.dataset.addressId;
                dialogTriggerClickHandler(evt, dialogElement);
                editButtonClickHandler(triggerElement, dialogElement);
            });
        });
    }
};

const editAddressDialogInit = () => {
    const dialogElement = document.querySelector('#editAddress');

    if (dialogElement) {
        displaysDialogInit(dialogElement);
        formSubmitDialogInit(dialogElement);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    editAddressDialogInit();
});
