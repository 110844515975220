/* global BX:writable */

import {api} from './api';

const initCartHandler = (buttonElement) => {
    const triggerElement = buttonElement.querySelector('[data-entity="clear-cart-trigger"]');

    if (triggerElement) {
        triggerElement.addEventListener('click', () => {
            clickClearCartTriggerHandler();
        });
    }
};

const clickClearCartTriggerHandler = () => {
    api.post('cart/clear/')
        .json()
        .then(() => {
            location.reload();
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

document.addEventListener('DOMContentLoaded', () => {
    const cartElement = document.querySelector('[data-cart-id]');

    if (cartElement) {
        initCartHandler(cartElement);
    }

    BX.addCustomEvent('OnBasketChange', function () {
        initCartHandler(cartElement);
    });
});
