import {actualizeAmount, initAmountHandler} from './product-amount';
import {actualizeSubscribes, initSubscribeHandler} from './product-subscribe';

document.addEventListener('DOMContentLoaded', () => {
    const cardElements = document.querySelectorAll('[data-product-card-id]');

    if (cardElements.length > 0) {
        cardElements.forEach((item) => {
            initAmountHandler(item);
            initSubscribeHandler(item);
        });

        actualizeAmount();
        setTimeout(() => actualizeSubscribes(), 400);
    }
});
