import {removeAlert} from '../form-utils/alert';

const switchDisplayForm = (dialogElement, isHidden = false, isClearForm = false) => {
    const form = dialogElement.querySelector('form[name]');

    if (form) {
        isClearForm && form.reset();

        form.classList.toggle('hidden', isHidden);
    }
};

const backDropClickHandler = ({currentTarget, target}, dialogElement) => {
    const isClickedOnBackDrop = target === currentTarget;

    if (isClickedOnBackDrop) {
        dialogElement.close();
    }
};

const dialogAddEventHandler = (dialogElement) => {
    dialogElement.addEventListener('click', (evt) => {
        backDropClickHandler(evt, dialogElement);
    });

    dialogElement.addEventListener(
        'close',
        () => {
            dialogElement.removeEventListener('click', backDropClickHandler);
            removeAlert();
            switchDisplayForm(dialogElement);
        },
        {
            once: true,
        }
    );
};

const dialogTriggerClickHandler = (evt, dialogElement) => {
    dialogElement.showModal();

    setTimeout(() => {
        dialogAddEventHandler(dialogElement);
    }, 100);
};

export {dialogTriggerClickHandler, switchDisplayForm};
