const MAX_AGE = 365 * 86400;

const buttonElement = document.querySelector('.cookie-accept-btn');

const setCookie = () => {
    document.cookie = `cookiesAccept=accept; path=/; max-age=${MAX_AGE}`;
};

if (buttonElement) {
    buttonElement.addEventListener('click', setCookie);
}
