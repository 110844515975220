/* global BX:writable */

import {api} from './api';
import {initFavoriteCountHandler} from './favorite-count';

const getProductId = (triggerElement) => {
    return parseInt(triggerElement.closest('[data-product-card-id]').dataset.productCardId, 10);
};

const deleteFromFavorite = (triggerElement) => {
    const productId = getProductId(triggerElement);

    if (!productId) {
        return;
    }

    api.delete(`favorite/product/${productId}/`)
        .json()
        .then(() => {
            switchDisplayFavorite(triggerElement);

            initFavoriteCountHandler();
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

const addToFavorite = (triggerElement) => {
    const productId = getProductId(triggerElement);

    if (!productId) {
        return;
    }

    api.post(`favorite/product/${productId}/`)
        .json()
        .then(() => {
            switchDisplayFavorite(triggerElement, true);

            initFavoriteCountHandler();
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

const clickFavoriteTriggerHandler = (triggerElement) => {
    triggerElement.classList.contains('active') ? deleteFromFavorite(triggerElement) : addToFavorite(triggerElement);
};

const initFavoriteHandler = (cardElement) => {
    const triggerElement = cardElement.querySelector('[data-entity="favorite-trigger"]');

    if (triggerElement) {
        triggerElement.addEventListener('click', () => {
            clickFavoriteTriggerHandler(triggerElement);
        });
    }
};

const switchDisplayFavorite = (favoriteTriggerElement, isActive = false) => {
    favoriteTriggerElement.classList.toggle('active', isActive);

    favoriteTriggerElement.title = isActive ? 'Удалить из избранного' : 'Добавить в избранное';
};

const setProductFavorite = (productId) => {
    const favoriteTriggerElement = document.querySelector(
        `[data-product-card-id="${productId}"] [data-entity="favorite-trigger"]`
    );

    if (favoriteTriggerElement) {
        switchDisplayFavorite(favoriteTriggerElement, true);
    }
};

const actualizeFavorites = () => {
    api.get('favorite/')
        .json()
        .then((response) => {
            if (response.products.length > 0) {
                response.products.forEach((item) => {
                    setProductFavorite(item);
                });
            }
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            console.error(errorJson.message);
        });
};

const basketChangeHandler = () => {
    const cardElements = document.querySelectorAll('[data-product-card-id]');

    if (cardElements.length > 0) {
        cardElements.forEach((item) => {
            initFavoriteHandler(item);
        });

        actualizeFavorites();
    }
};

document.addEventListener('DOMContentLoaded', () => {
    const cardElements = document.querySelectorAll('[data-product-card-id]');

    if (cardElements.length > 0) {
        cardElements.forEach((item) => {
            initFavoriteHandler(item);
        });

        setTimeout(() => actualizeFavorites(), 200);
    }

    BX.addCustomEvent('OnBasketChange', function () {
        basketChangeHandler();
    });
});

export {actualizeFavorites, initFavoriteHandler};
