import {dialogTriggerClickHandler, switchDisplayForm} from './dialogs/base';
import {lockButton, unlockButton, validateForm} from './form-utils/base';
import {api} from './api';
import {showAlert} from './form-utils/alert';
import {getCurrentUserId} from './utils';

const getProductId = (triggerElement) => {
    return parseInt(triggerElement.closest('[data-product-card-id]').dataset.productCardId, 10);
};

const switchDisplaySubscribeWrapper = (element, isSubscribed) => {
    if (isSubscribed) {
        element.textContent = 'Отправим уведомление на ваш email';
    }
};

const setProductSubscribe = (productId) => {
    const triggerWrapperElements = document.querySelectorAll(
        `[data-product-card-id="${productId}"] [data-entity="subscribe-trigger-wrapper"]`
    );

    if (triggerWrapperElements.length > 0) {
        triggerWrapperElements.forEach((item) => {
            switchDisplaySubscribeWrapper(item, true);
        });
    }
};

const formSubmitHandler = (evt, dialogElement, productId) => {
    evt.preventDefault();

    const formElement = evt.target;

    if (!validateForm(formElement)) {
        return;
    }

    const submitButton = formElement.querySelector('button[type="submit"]');

    lockButton(submitButton);

    const formData = new FormData(formElement);

    api.post(`product-subscribe/product/${productId}/`, {body: formData})
        .json()
        .then((response) => {
            showAlert(formElement, 'beforebegin', response.message, 'success');

            switchDisplayForm(dialogElement, true);

            unlockButton(submitButton);

            setProductSubscribe(productId);
        })
        .catch(async (error) => {
            const errorJson = await error.response.json();

            showAlert(formElement, 'beforebegin', errorJson.message, 'error');

            switchDisplayForm(dialogElement, true);

            unlockButton(submitButton);
        });
};

const formSubmitDialogInit = (dialogElement, productId) => {
    const form = dialogElement.querySelector('form[name="product-subscribe"]');

    if (form) {
        form.addEventListener(
            'submit',
            (evt) => {
                formSubmitHandler(evt, dialogElement, productId);
            },
            {
                once: true,
            }
        );
    }
};

const clickSubscribeTriggerHandler = (evt, triggerElement) => {
    const productId = getProductId(triggerElement);

    if (!productId) {
        return;
    }

    const dialogElement = document.querySelector('#productSubscribeForm');

    if (dialogElement) {
        dialogTriggerClickHandler(evt, dialogElement);
        formSubmitDialogInit(dialogElement, productId);
    }
};

const initSubscribeHandler = (cardElement) => {
    const triggerElement = cardElement.querySelector('[data-entity="subscribe-trigger"]');

    if (triggerElement) {
        triggerElement.addEventListener('click', (evt) => {
            clickSubscribeTriggerHandler(evt, triggerElement);
        });
    }
};

const actualizeSubscribes = () => {
    const userId = getCurrentUserId();

    if (userId > 0) {
        api.get(`product-subscribe/user/${userId}/`)
            .json()
            .then((response) => {
                if (response.products.length > 0) {
                    response.products.forEach((item) => {
                        setProductSubscribe(item);
                    });
                }
            })
            .catch(async (error) => {
                const errorJson = await error.response.json();

                console.error(errorJson.message);
            });
    }
};

export {actualizeSubscribes, initSubscribeHandler};
