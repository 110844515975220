import EmblaCarousel from 'embla-carousel';

const OPTIONS = {
    containScroll: 'trimSnaps',
    dragFree: true,
};

const sliderElements = document.querySelectorAll('.embla');

if (sliderElements.length > 0) {
    sliderElements.forEach((item) => {
        const viewportElement = item.querySelector('.embla__viewport');

        if (viewportElement) {
            EmblaCarousel(viewportElement, OPTIONS);
        }
    });
}
